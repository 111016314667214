import React from 'react'
import Layout from '../../components/resuable/layout/layout'
import SEO from '../../components/resuable/seo/seo'
import A2iResourcesHeader from '../../components/page/a2i-resources/a2i-resources-header/a2i-resources-header'
import A2iResourcesContent from '../../components/page/a2i-resources/a2i-resources-content/a2i-resources-content'

const Resources = () => {
  return (
    <Layout>
      <SEO 
        title="A2I world free resources"
        description="A list of killer websites and articles to go from zero to one!"
      />
      <A2iResourcesHeader />
      <A2iResourcesContent />
    </Layout>
  )
}

export default Resources
