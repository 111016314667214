import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Row from "../../../resuable/row/row"
import A2iResource from "../../../resuable/a2i-resource/a2i-resource"
import Section from "../../../resuable/section/section"

const StyledA2iResourcesContent = styled.div`
  .content {
    display: flex;
    flex-wrap: wrap;
  }
`

const A2iResourcesContent = props => {
  const data = useStaticQuery(graphql`
    query allResources {
      allWordpressWpA2IResources {
        edges {
          node {
            id
            title
            excerpt
            content
            slug
            categories {
              name
            }
            author {
              name
            }
            featured_media {
              source_url
            }
            acf {
              link
            }
          }
        }
      }
    }
  `)
  const { edges: resources } = data.allWordpressWpA2IResources

  return (
    <Section fullwidth={false}>
      <StyledA2iResourcesContent>
        <Row className="content">
          {resources.length !== 0
            ? resources.map(resource => {
                const {
                  id,
                  title,
                  content,
                  excerpt,
                  slug,
                  author,
                  categories,
                  featured_media,
                  acf,
                } = resource.node
                console.log(featured_media)
                return (
                  <A2iResource
                    key={id}
                    link={acf.link}
                    title={title}
                    content={content}
                    excerpt={excerpt}
                    slug={slug}
                    categories={categories}
                    author={author}
                    featured_media={featured_media}
                  />
                )
              })
            : null}
        </Row>
      </StyledA2iResourcesContent>
    </Section>
  )
}

A2iResourcesContent.defaultProps = {}

export default A2iResourcesContent
