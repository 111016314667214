import React from "react"
import styled from "styled-components"
import Hero from "../../../resuable/hero/hero"

const StyledA2iResourcesHeader = styled.div`
  
`

const A2iResourcesHeader = props => {
  return (
    <StyledA2iResourcesHeader>
      <Hero
        title="A2I world free resources"
        description="A list of killer websites and articles to go from zero to one!"
      />
    </StyledA2iResourcesHeader>
  )
}

A2iResourcesHeader.defaultProps = {}

export default A2iResourcesHeader
